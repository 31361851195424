import React from "react"
import FormInput from "../../Elements/Form/FormInput"
import FormDate from "../../Elements/Form/FormDate"
import FormSelect from "../../Elements/Form/FormSelect"
import paymentOptions from "../utils/paymentOptions.json"
import FormTextArea from "../../Elements/Form/FormTextarea"
import Section from "../../../components/Elements/Section"

const RefillFormFields = ({ values }) => {
  const handleOnKeyDown = (event) => {
    let { value } = event.target
    let numberValue = parseInt(value)
    if (numberValue && numberValue > 0)
      event.target.value = numberValue.toString().padStart(7, "0")
    else event.target.value = ""
  }

  return (
    <Section title="Order Details">
      {" "}
      <FormInput
        label="First Name"
        name="firstName"
        placeholder="Juan"
        isRequired
      />
      <FormInput
        label="Last Name"
        name="lastName"
        placeholder="Dela Cruz Jr."
        isRequired
      />
      <FormInput
        label="Email Address"
        name="emailAddress"
        placeholder="jdelacruz@mail.com"
        type="email"
        isRequired
      />
      <FormInput
        label="Mobile Number"
        placeholder="09991234567"
        type="tel"
        pattern="[0,9]{2}[0-9]{9}"
        name="mobileNumber"
        maxLength={11}
        isRequired
        isNumeric
      />
      <FormInput
        label="Refill Code"
        name="latestOrderNumber"
        placeholder="0000000"
        helper="Please refer to the SMS sent by our pharmacist"
        handleOnKeyDown={handleOnKeyDown}
        hasAddons
        addonLeft={<span className="button is-static">RF-</span>}
        isRequired
      />
      <FormDate
        values={values.preferredDeliveryDate}
        label="Preferred Delivery Date"
        name="preferredDeliveryDate"
        isRequired
      />
      <FormInput
        label="Preferred Delivery Time"
        name="preferredDeliveryTime"
        isRequired
      />
      <FormSelect
        title="Payment Method"
        options={paymentOptions}
        label="Payment Method"
        name="paymentOption"
        value={values.paymentOption}
        isRequired
        helper="Our pharmacist will send the order total and the payment details of the dispensing channel who can accommodate your delivery"
      />
      <FormTextArea
        label="Order notes"
        name="orderNotes"
        helper="Please provide delivery details or special requests such as delivery address, preferred dispensing channel, or authorized personnel"
      />
    </Section>
  )
}

export default RefillFormFields
